<template>
  <div class="wrapper-menu-mobile">
    <!-- <el-button type="info" class="btn" @click="openModal()">Menu</el-button> -->
    <fas class="icon-open-modal" icon="bars" @click="openModal()" />
    <ModalMenu v-show="visible" @close="close">
      <template v-slot:header>
        <div class="inner-header">
          <img
            class="image-logo"
            src="@/assets/images/revenuehunt-logo.png"
            alt="logo"
          />
          <p class="font s-28 r-regular">Store Analytics</p>
        </div>
      </template>
      <template v-slot:body> </template>
    </ModalMenu>
  </div>
</template>

<script>
import ModalMenu from "./ModalMenu.vue";

export default {
  name: "Menu-mobile",
  components: {
    ModalMenu,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    openModal() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-menu-mobile {
  display: none;
  line-height: 58px;
  .fa-bars {
    display: flex;
    margin-top: 16px;
  }
  @media (max-width: 768px) {
    // TODO TO DO
    // display: block;
  }
  .btn {
    margin-right: 15px;
  }
}
.icon-open-modal {
  width: 30px;
  font-size: 25px;
  margin-right: 1em;
  color: $color-action-icon;
  &:hover {
    cursor: pointer;
  }
}
.inner-header {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  img {
    margin-right: 8px;
  }
}
</style>
