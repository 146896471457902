<template>
  <div class="header-views">
    <div class="inner-header-views inner-header-dashboard">
      <div class="flex-content f-start">
        <MenuMobile />
        <img
          class="image-logo"
          src="@/assets/images/revenuehunt-logo.png"
          alt="logo"
        />
        <p class="font s-28 c-primary f-light mtop-10">{{ title }}</p>
      </div>
      <DatePicker />
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/UI/DatePicker.vue";
import MenuMobile from "@/components/UI/MenuMobile.vue";

export default {
  name: "Header-dashboard-component",
  components: {
    DatePicker,
    MenuMobile,
  },
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.header-views {
  width: 100%;
  padding: 0 0 0 60px;
  min-height: 3em;
  border-bottom: 1px solid $color-border;
}

.content-max-view .header-views {
  padding: 0;
}

.inner-header-views {
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em 2em;
}

.inner-header-dashboard {
  max-width: 1400px;
}

.image-logo {
  width: 40px;
  height: 40px;
  margin-top: 7px;
  margin-right: 1em;
}

@media (max-width: 768px) {
  .header-views {
    padding: 0;
  }
}

@media only screen and (min-width: 920px) {
  .inner-header-views {
    display: flex;
  }
}
</style>
