import axios from "axios";
//// import store from 'prq_root_path/store'
import router from "@/router";
//// import { Message } from 'element-ui'
import { setToken, getToken, removeToken } from "@/utils/auth";

//// import createApp from '@shopify/app-bridge'
//// import { Redirect } from '@shopify/app-bridge/actions'
import Cookies from "js-cookie";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  // baseURL: "http://localhost:4001",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    const token = getToken();
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      const parsed_token = token;
      config.headers = { ...config.headers, ...parsed_token };
    }
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error) // for debug
    // const path = router.currentRoute && router.currentRoute.path;
    // window.isogramEvent('Timeout Error', error, path, 0)
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    const headers = response.headers;

    // NOTE: when access-token has not changed devise_token_auth sends and empty access_token ''
    if (
      response.headers["access-token"] !== undefined &&
      response.headers["access-token"] !== ""
    ) {
      const token = {
        "access-token": headers["access-token"],
        client: headers.client,
        uid: headers.uid,
      };
      setToken(token);
    }

    return res;
  },
  (error) => {
    // Logout user if unauthorized
    const path = router.currentRoute && router.currentRoute.path;
    // window.isogramEvent('Timeout Error', error, path, 0)
    if (error.response && error.response.status === 401) {
      removeToken();
      //// store.dispatch('user/resetToken')
      //// Message.error('Auth Error => Logged out')
      // If inside Shopify iframe
      if (window.self === window.top) {
        // other pages that do not have permission to access are redirected to the login page.
        router.push({ path: `/login?redirect=${path}` });
      } else {
        if (Cookies.get("shopOrigin")) {
          console.log("preparing to redirect");

          /********
          const app = createApp({
            apiKey: Cookies.get("apiKey"),
            shopOrigin: Cookies.get("shopOrigin"),
          });

          const redirect = Redirect.create(app);
          redirect.dispatch(Redirect.Action.APP, "/");
         */

          console.log("Should be redirected to Shopify /");
        } else {
          console.log("shopOrigin not set (request)");
          router.push({ path: "public/safari" });
        }

        // router.push({ path: 'public/oops'})
      }
    }
    // console.log(error) // for debug
    //// Message({
    // message: error.message,
    // type: 'error',
    // duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
