import Vuex from "vuex";
import shop from "./modules/shop";
import router from "../router/index";
import { createSegment, getSegments, deleteSegment } from "@/api/segment";

const store = new Vuex.Store({
  state: {
    editingSegment: undefined,
    segments: [],
    activeSegmentsIds: [],
    segment: {
      id: "",
      title: "",
      description: "",
      category: [],
      device: "",
      utm_option: "",
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      landing_page: "",
      locationCity: "",
      locationRegion: "",
      locationCountry: "",
    },
    dates: [],
    period: "day",
    showChart: false,
    comparing: false,
  },
  mutations: {
    switchActiveSegmentsIds(state, segmentIds) {
      var index = state.activeSegmentsIds.indexOf(segmentIds[0]);
      if (index !== -1) {
        state.activeSegmentsIds[index] = segmentIds[1];
      }
    },
    addActiveSegmentId(state, segmentId) {
      state.activeSegmentsIds.push(segmentId);
    },
    deleteActiveSegmentId(state, segmentId) {
      state.activeSegmentsIds = state.activeSegmentsIds.filter(
        (id) => id !== segmentId
      );
    },
    resetActiveSegments(state) {
      state.activeSegmentsIds = [];
    },
    deleteSegment(state, segmentId) {
      state.segments = state.segments.filter(
        (segment) => segment.id !== segmentId
      );
    },
    addSegment(state, segment) {
      state.segments.push(segment);
    },
    setSegments(state, segments) {
      state.segments = segments;
    },
    setEditingSegment(state, segment) {
      state.editingSegment = segment;
    },
    setDates(state, payload) {
      // console.log("index.js - setDates");
      state.showChart = false;
      state.dates = payload;
    },
    setPeriod(state, payload) {
      // console.log("index.js - setPeriod");
      state.showChart = false;
      state.period = payload;
    },
    setShowChart(state, payload) {
      // console.log("index.js - setShowChart");
      state.showChart = payload;
    },
    setComparing(state, payload) {
      // console.log("index.js - setComparing");
      state.comparing = payload;
    },
    savedSegments(state, payload) {
      state.segments = payload;
    },
    segment(state, payload) {
      if (!state.segments.find((item) => item.id === payload)) {
        router.push("/segments/segments-list");
        return;
      }
      state.segment = state.segments.find((item) => item.id === payload);
    },
  },
  actions: {
    SWITCH_ACTIVE_SEGMENT_IDS({ commit }, segmentIds) {
      commit("switchActiveSegmentsIds", segmentIds);
    },
    ADD_ACTIVE_SEGMENT_ID({ commit }, segmentId) {
      commit("addActiveSegmentId", segmentId);
    },
    DELETE_ACTIVE_SEGMENT_ID({ commit }, segmentId) {
      commit("deleteActiveSegmentId", segmentId);
    },
    RESET_ACTIVE_SEGMENTS({ commit }) {
      commit("resetActiveSegments");
    },
    DELETE_SEGMENT({ commit }, segmentId) {
      return new Promise((resolve) => {
        deleteSegment(segmentId).then((response) => {
          console.log(response);
          commit("deleteSegment", segmentId);
          resolve();
        });
      });
    },
    CREATE_SEGMENT({ commit }) {
      return new Promise((resolve) => {
        createSegment().then((response) => {
          // console.log(response);
          commit("addSegment", response);
          commit("addActiveSegmentId", response.id);
          resolve(response.id);
        });
      });
    },
    GET_SEGMENTS({ commit, state }, forceGet = false) {
      if (state.segments.length === 0 || forceGet) {
        getSegments().then((response) => {
          // console.log(response);
          commit("setSegments", response);
        });
      } else {
        console.log("already have segments");
      }
    },
    SET_SEGMENTS({ commit }, segment) {
      commit("set", segment);
    },
    SET_EDITING_SEGMENT({ commit }, segment) {
      commit("setEditingSegment", segment);
    },
    SET_SEGMENT({ commit }, id) {
      commit("segment", id);
    },
    SET_UPDATE_SEGMENT({ commit }, segment) {
      commit("update", segment);
    },
    SET_DATES({ commit }, dates) {
      commit("setDates", dates);
    },
    SET_PERIOD({ commit }, period) {
      commit("setPeriod", period);
    },
    SET_SHOW_CHART({ commit }, showChart) {
      commit("setShowChart", showChart);
    },
    SET_COMPARING({ commit }, comparing) {
      commit("setComparing", comparing);
    },
  },
  getters: {
    getEditingSegment: (state) => {
      return state.editingSegment;
    },
    getActiveSegments: (state) => {
      return state.segments.filter((segment) =>
        state.activeSegmentsIds.includes(segment.id)
      );
    },
    getInactiveSegments: (state) => {
      return state.segments.filter(
        (segment) => !state.activeSegmentsIds.includes(segment.id)
      );
    },
    isSegmentIdActive: (state) => (segmentId) => {
      return state.activeSegmentsIds.includes(segmentId);
    },
    daysRange: (state) => {
      // Calculate the number of days in the dates range
      var timestamp_start = new Date(state.dates[0]).getTime();
      var timestamp_end = new Date(state.dates[1]).getTime() + 1000 * 3600 * 24;
      return (timestamp_end - timestamp_start) / (1000 * 3600 * 24);
    },
  },
  modules: {
    shop,
  },
});

export default store;
