// import { createRouter, createWebHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import AuthView from "../views/AuthView.vue";

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return {
        path: "/metrics/total-sales/",
        query: { q: to.params.searchText },
      };
    },
    name: "home",
    component: HomeView,
  },
  // {
  // path: "/auth",
  // name: "authentication",
  // component: AuthView,
  // },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/metrics",
    name: "metrics",
    component: () => import("../views/MetricsView.vue"),
  },
  {
    path: "/segments",
    name: "segments",
    component: () => import("../views/SegmentsView.vue"),
  },
  {
    path: "/funnels",
    name: "funnels",
    component: () => import("../views/FunnelsView.vue"),
  },
  {
    path: "/integrations",
    name: "integrations",
    component: () => import("../views/IntegrationsView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/metrics/total-sales",
    name: "total-sales",
    component: () => import("../views/RevenueTotalSalesView.vue"),
  },
  {
    path: "/metrics/accumulated-revenue",
    name: "accumulated-revenue",
    component: () => import("../views/RevenueAccumulatedView.vue"),
  },
  {
    path: "/metrics/annual-run-rate",
    name: "annual-run-rate",
    component: () => import("../views/RevenueAnnualRunRateView.vue"),
  },
  {
    path: "/metrics/sales-growth-rate",
    name: "sales-growth-rate",
    component: () => import("../views/RevenueSalesGrowthRateView.vue"),
  },
  {
    path: "/metrics/traffic",
    name: "traffic",
    component: () => import("../views/MarketingTrafficView.vue"),
  },
  {
    path: "/metrics/roas",
    name: "roas",
    component: () => import("../views/MarketingRoasView.vue"),
  },
  {
    path: "/metrics/cac",
    name: "cac",
    component: () => import("../views/MarketingCacView.vue"),
  },
  {
    path: "/metrics/clv-cac-ratio",
    name: "clv-cac-ratio",
    component: () => import("../views/MarketingClvCacRatioView.vue"),
  },
  {
    path: "/metrics/total-orders",
    name: "total-orders",
    component: () => import("../views/OrdersTotalOrdersView.vue"),
  },
  {
    path: "/metrics/aov",
    name: "aov",
    component: () => import("../views/OrdersAovView.vue"),
  },
  {
    path: "/metrics/frequency",
    name: "frequency",
    component: () => import("../views/OrdersFrequencyView.vue"),
  },
  {
    path: "/metrics/top-products",
    name: "top-products",
    component: () => import("../views/OrdersTopProductsView.vue"),
  },
  {
    path: "/metrics/top-customers",
    name: "top-customers",
    component: () => import("../views/CustomersTopCustomersView.vue"),
  },
  {
    path: "/metrics/clv",
    name: "clv",
    component: () => import("../views/CustomersClvView.vue"),
  },
  {
    path: "/metrics/returns",
    name: "returns",
    component: () => import("../views/CostsReturnsView.vue"),
  },
  {
    path: "/metrics/refunds",
    name: "refunds",
    component: () => import("../views/CostsRefundsView.vue"),
  },
  {
    path: "/metrics/discounts",
    name: "discounts",
    component: () => import("../views/CostsDiscountsView.vue"),
  },
  {
    path: "/metrics/shipping",
    name: "shipping",
    component: () => import("../views/CostsShippingView.vue"),
  },
  {
    path: "/segments/segments-list",
    name: "segments-list",
    component: () => import("../views/SegmentsList.vue"),
  },
  {
    path: "/segments/segments-form",
    name: "segments-form",
    component: () => import("../views/SegmentsForm.vue"),
  },
  {
    path: "/segments/edit-segment/:id",
    name: "edit-segment",
    component: () => import("../views/EditSegment.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
