<template>
  <!-- <div class="card table-card">
    <div class="header-card">
      <div>
        <el-select v-model="value" filterable placeholder="Select by Country">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <p class="font s-24 f-light c-primary">Top Selling Products</p>
      <p>Total: 50</p>
      <el-popover
        placement="bottom"
        title="Top Selling Products"
        :width="230"
        trigger="hover"
        effect="dark"
      >
        <el-button>Info</el-button>
      </el-popover>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed label="Product" width="90">
          <template v-slot="scope">
            <el-image
              class="image-product"
              :src="scope.row.image"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column fixed prop="name" label="Name" width="180">
          <template v-slot="scope">
            <p class="font s-14 f-bold c-marine">{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="Date" width="100%">
          <template v-slot="scope">
            <p class="font s-14 f-bold c-forest">{{ scope.row.date }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date1" label="Date" width="100%">
          <template v-slot="scope">
            <p class="font s-14 f-bold c-forest">{{ scope.row.date1 }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date2" label="Date" width="100%">
          <template v-slot="scope">
            <p class="font s-14 f-bold c-forest">{{ scope.row.date2 }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-block">
        <el-pagination layout="prev, pager, next" :total="50" />
      </div>
    </div>
  </div> -->
  <div class="card card-table-prducts">
    <div
      class="flex-content"
      v-for="(product, index) in tableData"
      :key="index.id"
    >
      <div class="header-table-product">
        <el-image
          class="img-product"
          :src="product.image"
          fit="cover"
        ></el-image>
        <p>{{ product.name }}</p>
      </div>
      <div class="flex-content s-between body-table-product">
        <div
          v-for="(sales, indexProduct) in product.sales"
          :key="indexProduct.id"
          class="content-date"
        >
          <p>{{ sales.month }}</p>
          <p>{{ sales.result }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Table-top-selling-products",

  data: function () {
    return {
      tableData: [
        {
          image: require("@/assets/images/example-product-1.png"),
          name: "Curly Booster Cosmetic product",
          sales: [
            { month: "jan", result: 450, dif: -10 },
            { month: "Feb", result: 550, dif: 3 },
            { month: "Mar", result: 250, dif: -3 },
            { month: "Apr", result: 540, dif: 23 },
            { month: "Mai", result: 550, dif: 4 },
            { month: "Jun", result: 650, dif: 6 },
            { month: "Jul", result: 450, dif: -15 },
            { month: "Aug", result: 300, dif: -10 },
            { month: "Sep", result: 400, dif: 10 },
            { month: "Oct", result: 530, dif: 12 },
            { month: "Nov", result: 580, dif: 5 },
            { month: "Dec", result: 500, dif: -5 },
          ],
        },
        {
          image: require("@/assets/images/example-product-2.png"),
          name: "Curly Booster",
          sales: [
            { month: "jan", result: 450, dif: -10 },
            { month: "Feb", result: 550, dif: 3 },
            { month: "Mar", result: 250, dif: -3 },
            { month: "Apr", result: 540, dif: 23 },
            { month: "Mai", result: 550, dif: 4 },
            { month: "Jun", result: 650, dif: 6 },
            { month: "Jul", result: 450, dif: -15 },
            { month: "Aug", result: 300, dif: -10 },
            { month: "Sep", result: 400, dif: 10 },
            { month: "Oct", result: 530, dif: 12 },
            { month: "Nov", result: 580, dif: 5 },
            { month: "Dec", result: 500, dif: -5 },
          ],
        },
      ],
      options: [
        {
          value: "United States",
          label: "United States",
        },
        {
          value: "Spain",
          label: "Spain",
        },
      ],
      value: " ",
    };
  },
};
</script>
<style scoped lang="scss">
.wrapper-table {
  padding: 1em;
  background: #fff;
}
/* .content-table {
  min-height: 3em;
}
.image-product {
  border-radius: 5px;
}
.pagination-block {
  height: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1em;
} */
.card-table-prducts {
  overflow: scroll;
  padding: 1em;
}
.header-table-product {
  display: flex;
  min-width: 260px;
  border-right: 1px solid gray;
  .img-product {
    width: 80px;
    border-radius: 5px;
  }
}
.body-table-product {
  /* overflow: scroll; */
  width: 1300px;
  .content-date {
    width: 100px;
  }
}
</style>
