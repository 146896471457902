<template>
  <div
    class="wrapper-tag"
    :class="{
      'value-up': value > 0,
      'value-down': value < 0,
      'not-value': !value || value === 0,
    }"
  >
    <fas v-if="!value || value === 0" class="icon icon-not" icon="dot-circle" />
    <fas v-else-if="value > 0" class="icon icon-up" icon="arrow-up" />
    <fas v-else class="icon icon-down" icon="arrow-down" />
    <span v-if="!value || value === 0" class="font f-bold s-14">-</span>
    <span v-else class="font f-bold s-14"
      >{{
        this.formatSeparators(this.formatInfinity(value), 0, ",", ".")
      }}%</span
    >
  </div>
</template>
<script>
import numbers from "@/mixins/numbers.js";
export default {
  name: "Tag-value",
  props: {
    value: Number,
  },
  mixins: [numbers],
};
</script>
<style scoped lang="scss">
.wrapper-tag,
.wrapper-tag span {
  display: inline-block;
  height: 25px;
  line-height: 25px;
}
.wrapper-tag {
  text-align: center;
  min-width: 40px;
  svg {
    float: left;
  }
  .icon {
    border-radius: 20px;
    margin-right: 0.5em;
    width: 18px;
    height: 18px;
    padding: 3px;
  }
  &.value-up {
    color: $color-forest;
    .icon-up {
      background: $color-background-forest;
    }
  }
  &.value-down {
    color: $color-rose;
    .icon-down {
      background: $color-background-rose;
    }
  }
  &.not-value {
    color: $color-secondary-text;
  }
  .icon-not {
    background: transparent;
    color: $color-disable-text;
    width: 24px;
    height: 24px;
    padding: 0;
  }
}
</style>
