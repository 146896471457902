<template>
  <div class="card resume-card">
    <!-- <header class="header-card">
      <p class="font s-18 c-primary f-light t-title">Average Order Value</p>
      <div class="flex-content s-between">
        <span class="font s-20 c-primary f-bold">
          Rate:{{ " " }}
          <label class="font s-16 c-forest f-bold">15 %</label>
        </span>
        <TagValue value="-7.5" />
      </div>
    </header> -->
    <body class="body-card">
      <apexchart
        type="area"
        :options="chartOptions"
        :series="series"
        height="100"
        width="100%"
      ></apexchart>
    </body>
  </div>
</template>

<script>
export default {
  name: "Revenue-sparkline",
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "area",
          toolbar: { show: false },
          animations: {
            speed: 1000,
          },
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: false,
            enabledOnSeries: undefined,
            top: 0,
            left: 0,
            bottom: 100,
            blur: 3,
            color: "#8661FF",
            opacity: 0.15,
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#8661FF",
            shadeIntensity: 0.2,
          },
        },
        plotOptions: {
          line: {
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          lineCap: "butt",
          width: 3,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          name: "Average Order Value",
          data: [14, 11, 17, 16, 23, 21, 27, 16, 20, 19, 23, 18],
        },
      ],
    };
  },
};
</script>
