// import { getOrders } from "@/api/order";
import { getShopInfo } from "@/api/shop";

const state = {
  id: undefined,
  name: undefined,
  currency: undefined,
  domain: undefined,
  email: undefined,
  locale: undefined,
  money_format: undefined,
  money_with_currency_format: undefined,
  myshopify_domain: undefined,
  last_full_sync_at: undefined,
  orders_count: undefined,
  token: undefined,
};

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
  setShopInfo(state, info) {
    state.name = info.name;
    state.currency = info.currency;
    state.domain = info.domain;
    state.email = info.email;
    state.locale = info.locale;
    state.money_format = info.money_format;
    state.money_with_currency_format = info.money_with_currency_format;
    state.myshopify_domain = info.myshopify_domain;
    state.last_full_sync_at = info.last_full_sync_at;
    state.orders_count = info.orders_count;
  },
};
const actions = {
  SET_SHOP_INFO({ commit }) {
    getShopInfo()
      .then((response) => {
        if (response.state === "uninstalled") {
          console.log("Inside uninstalled");
          alert(
            "It looks like you have uninstalled the app. Please install it again"
          );
          window.location.href = response.admin_url;
        }
        // console.log(response);
        commit("setShopInfo", response);
      })
      .catch((err) => {
        console.log("getShopInfo ERROR");
        console.log(err);
      });
  },
};
// const getters = {};
const modules = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  modules,
};
