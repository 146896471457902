import Cookies from "js-cookie";
import shop from "../store/modules/shop";

const TokenKey = "analytics_token";

export function getToken() {
  // console_log(['getToken', shop.state.token])
  const token = shop.state.token;
  // NOTE we are prioritizing shop.state.token over cookies token as this will make sure we can have two stores on different apps
  if (token) {
    // console_log(['getToken state.token', token])
    return token;
  } else {
    const cookiesToken = Cookies.get(TokenKey);
    if (cookiesToken) {
      const parsedCookiesToken = JSON.parse(cookiesToken);
      // console_log(['getToken cookies', parsedCookiesToken])
      return parsedCookiesToken;
    }
  }
  // console_log('getting token')
}

export function getIsAffiliate() {
  // console.log('getting token')
  return Cookies.get("is_affiliate");
}

export function setToken(token) {
  const newToken = JSON.stringify(token);
  // Only set secure cookie on development environment
  const is_local =
    !!window.location.href.match("/localhost:8080/") ||
    !!window.location.href.match("/192.168.1.200:8200/");

  shop.mutations.setToken(shop.state, token);

  // console_log(['setToken shop.state.token', shop.state.token]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    // return Cookies.set(TokenKey, token)
    return Cookies.set(TokenKey, newToken, {
      sameSite: "None",
      secure: !is_local,
    });
  } else if (!is_local) {
    return Cookies.set(TokenKey, newToken, {
      sameSite: "None",
      secure: !is_local,
    });
  } else {
    // alert('Make sure in production the old (commented) option works')
    // return Cookies.set(TokenKey, token)
    return Cookies.set(TokenKey, newToken, {
      sameSite: "Lax",
      secure: !is_local,
    });
  }
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
