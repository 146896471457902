import request from "@/utils/request";

export function getSegments() {
  return request({
    url: `/segments`,
    method: "get",
  });
}

export function getSegmentsDimensions(data, params) {
  // NOTE: we are passing a full segment but only using the dimensions field on the backend
  return request({
    url: `/segments/dimensions`,
    method: "post",
    data,
    params,
  });
}

export function getSegment(segmentId) {
  return request({
    url: `/segments/${segmentId}`,
    method: "get",
  });
}

export function createSegment(data) {
  return request({
    url: `/segments`,
    method: "post",
    data,
  });
}

export function updateSegment(segmentId, data) {
  return request({
    url: `/segments/${segmentId}`,
    method: "put",
    data,
  });
}

export function deleteSegment(segmentId) {
  return request({
    url: `/segments/${segmentId}`,
    method: "delete",
  });
}
