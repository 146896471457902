<template>
  <p>Get orders</p>
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "Card-orders",
  setup() {
    const store = useStore();
    onMounted(() => {
      console.log(store.state.orders);
      store.dispatch("getOrders");
    });
  },
};
</script>
