<template>
  <div class="demo-date-picker">
    <el-date-picker
      v-if="dates"
      v-model="computedDates"
      type="daterange"
      start-placeholder="Start date"
      end-placeholder="End date"
      :disabled-date="disabledDate"
      :shortcuts="datepickerShortcuts()"
      @change="datesChange"
    ></el-date-picker>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Date-picker",
  props: {
    shortcuts: Array,
  },
  data: function () {
    return {
      yesterday: this.daysAgoStart(1),
    };
  },
  computed: {
    ...mapState(["dates"]),
    computedDates: {
      get() {
        return this.dates;
      },
      set(val) {
        this.SET_DATES(val);
      },
    },
  },
  created() {
    if (this.computedDates.length === 0) {
      var initialDates = [this.daysAgoStart(31), this.daysAgoStart(1)];
      this.setDates(initialDates);
    }
  },
  methods: {
    ...mapActions(["SET_DATES"]),
    disabledDate(time) {
      return time.getTime() > this.yesterday;
    },
    datesChange(dates) {
      console.log(dates);
    },
    daysAgoStart(days) {
      var now = new Date();
      var date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0,
        0
      );
      var timestamp = date.getTime();
      timestamp = timestamp - 1000 * 3600 * 24 * days;
      return new Date(timestamp);
    },
    setDates(dates) {
      this.SET_DATES(dates);
      return dates;
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1, 0);
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0);
    },
    getFirstDayOfYear(year) {
      return new Date(year, 0, 1, 0);
    },
    getLastDayOfYear(year) {
      return new Date(year, 11, 31);
    },
    set_dates_last_days(num) {
      console.log("set_dates_last_days " + num);
      return this.setDates([this.daysAgoStart(num + 1), this.yesterday]);
    },
    set_dates_month(m) {
      const startAt = this.getFirstDayOfMonth(
        this.yesterday.getFullYear(),
        this.yesterday.getMonth() + m
      );
      const endAt = this.getLastDayOfMonth(
        this.yesterday.getFullYear(),
        this.yesterday.getMonth() + m
      );
      return this.setDates([startAt, endAt]);
    },
    set_dates_year(y) {
      const startAt = this.getFirstDayOfYear(this.yesterday.getFullYear() + y);
      const endAt = this.getLastDayOfYear(this.yesterday.getFullYear() + y);
      return this.setDates([startAt, endAt]);
    },
    set_dates_all_time() {
      console.log("graphs.js - set_dates_all_time");
      // TODO TO DO : get store creation date (not on our platform, on Shopify)
      const startAt = new Date(
        new Date(new Date().setDate(this.yesterday.getDate() - 365 * 20))
      );
      return this.setDates([startAt, this.yesterday]);
    },
    datepickerShortcuts() {
      var dis = this;
      return [
        {
          text: "Current month",
          value: () => {
            return dis.set_dates_month(0);
          },
        },
        {
          text: "Previous month",
          value: () => {
            return dis.set_dates_month(-1);
          },
        },
        {
          text: "Last 30 days",
          value: () => {
            return dis.set_dates_last_days(30);
          },
        },
        {
          text: "Last 90 days",
          value: () => {
            return dis.set_dates_last_days(90);
          },
        },
        {
          text: "Last 180 days",
          value: () => {
            return dis.set_dates_last_days(180);
          },
        },
        {
          text: "Last 365 days",
          value: () => {
            return dis.set_dates_last_days(365);
          },
        },
        {
          text: "Current year",
          value: () => {
            return dis.set_dates_year(0);
          },
        },
        {
          text: "Previous year",
          value: () => {
            return dis.set_dates_year(-1);
          },
        },
        {
          text: "All time",
          value: () => {
            return dis.set_dates_all_time();
          },
        },
      ];
    },
  },
};
</script>

<style>
.demo-date-picker {
  max-width: 100%;
}
.el-date-editor {
  width: 240px !important;
  max-width: 100%;
}
.el-date-range-picker__content {
  padding: 9px 16px;
}
.el-picker-panel__sidebar {
  width: 120px;
  padding: 0;
}
.el-picker-panel__shortcut {
  font-size: 13px;
  padding: 3px 12px;
  border-bottom: 1px solid #f7f7fd;
}
.el-picker-panel__shortcut:hover {
  background-color: #fcfcff;
}
.el-picker-panel__shortcut:last-of-type {
  border-bottom: none;
}
.el-range__close-icon {
  display: none;
}
</style>
