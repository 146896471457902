<template>
  <!--<Navbar />-->
  <Sidebar />
  <router-view v-if="authenticated" />
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import { mapActions } from "vuex";
// import Navbar from "@/components/Navbar.vue";
// authentication
import router from "@/router";
import { setToken, removeToken } from "@/utils/auth";
import { getHeaders } from "@/api/auth";

export default {
  name: "App-vue",
  components: {
    Sidebar,
    // Navbar,
  },
  data: function () {
    return {
      authenticated: false,
    };
  },
  created() {
    this.authenticate();
  },
  methods: {
    ...mapActions("shop", ["SET_SHOP_INFO"]),
    authenticate() {
      // console.log('in authenticate');
      if (window.location.href.includes("key=")) {
        var key = window.location.href.split("key=")[1];
        getHeaders(key)
          .then((response) => {
            // console.log(response);
            if (
              response["access-token"] !== undefined &&
              response["access-token"] !== ""
            ) {
              const token = {
                "access-token": response["access-token"],
                client: response.client,
                uid: response.uid,
              };
              setToken(token);

              this.authenticated = true;

              // console.log("token set");

              // Pull shop info
              this.SET_SHOP_INFO();
              // console.log('pulled shop info')
              router.push({ path: "/" });
              // console.log('pushed to path /')
              return true;
            } else {
              removeToken();
            }
          })
          .catch((err) => {
            console.log("getHeaders ERROR");
            console.log(err);
          });
      } else {
        this.authenticated = true;
        // Pull shop info
        this.SET_SHOP_INFO();
        // console.log("no key passed");
      }
    },
  },
};
</script>
