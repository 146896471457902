<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> Default Header </slot>
          <button
            type="button"
            class="close-btn"
            @click="close"
            aria-label="Close Modal"
          >
            <fas class="close-btn" icon="xmark" />
          </button>
        </header>
        <main class="modal-body" id="modalDescription">
          <ul class="list-menu-mobile">
            <span class="font s-16 f-regular c-primary">Navigation</span>
            <li>
              <router-link class="font t-link" to="/" @click="close">
                <div class="flex-content f-start">
                  <div class="wrapper-icon wrapper-home">
                    <fas class="icon" icon="circle-stop" />
                  </div>
                  <p class="font s-24 t-title c-primary r-regular">Home</p>
                </div>
              </router-link>
            </li>
            <li>
              <el-collapse value="1" accordion class="accordion-menu-mobile">
                <el-collapse-item class="el-collapse-head-8811" name="1">
                  <template v-slot:title>
                    <div class="wrapper-icon wrapper-home">
                      <fas class="icon" icon="square-poll-vertical" />
                    </div>
                    <p class="font s-24 t-title c-primary r-regular">Metrics</p>
                  </template>
                  <div class="ul-inner-collapse">
                    <ul class="ul-submenu-metrics">
                      <span>Revenue</span>
                      <li v-for="revenue in revenueItems" :key="revenue.id">
                        <router-link :to="`/metrics/${revenue.url}`">
                          <div class="inner-link">
                            {{ revenue.name }}
                          </div>
                        </router-link>
                      </li>
                      <span>Marketing</span>
                      <li
                        v-for="marketing in marketingItems"
                        :key="marketing.id"
                      >
                        <router-link :to="`/metrics/${marketing.url}`">
                          <div class="inner-link">
                            {{ marketing.name }}
                          </div>
                        </router-link>
                      </li>
                      <span>Orders</span>
                      <li v-for="orders in ordersItems" :key="orders.id">
                        <router-link :to="`/metrics/${orders.url}`">
                          <div class="inner-link">
                            {{ orders.name }}
                          </div>
                        </router-link>
                      </li>
                      <span>Customers</span>
                      <li
                        v-for="customers in customersItems"
                        :key="customers.id"
                      >
                        <router-link :to="`/metrics/${customers.url}`">
                          <div class="inner-link">
                            {{ customers.name }}
                          </div>
                        </router-link>
                      </li>
                      <span>Costs</span>
                      <li v-for="costs in costsItems" :key="costs.id">
                        <router-link :to="`/metrics/${costs.url}`">
                          <div class="inner-link">
                            {{ costs.name }}
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </li>
            <li>
              <router-link
                class="font t-link"
                to="/integrations"
                @click="close"
              >
                <div class="flex-content f-start">
                  <div class="wrapper-icon wrapper-home">
                    <fas class="icon" icon="plug" />
                  </div>
                  <p class="font s-24 t-title c-primary r-regular">
                    Integrations
                  </p>
                </div>
              </router-link>
            </li>
          </ul>
          <ul>
            <span class="font s-16 f-regular">Configuration</span>
            <li>
              <router-link
                class="font t-link"
                to="/integrations"
                @click="close"
              >
                <div class="flex-content f-start">
                  <div class="wrapper-icon wrapper-home">
                    <fas class="icon" icon="cog" />
                  </div>
                  <p class="font s-24 t-title c-primary r-regular">Settings</p>
                </div>
              </router-link>
            </li>
          </ul>
        </main>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ModalMenu-component",
  methods: {
    close() {
      this.$emit("close");
    },
  },
  data: function () {
    return {
      activeNames: null,
    };
  },
  setup() {
    const revenueItems = ref([
      { name: "Total Sales", url: "total-sales" },
      { name: "Accumulated Revenue", url: "accumulated-revenue" },
      { name: "Anual Run Rate", url: "annual-run-rate" },
      { name: "Sales Growth Rate", url: "sales-growth-rate" },
    ]);
    const marketingItems = ref([
      { name: "Traffic", url: "traffic" },
      { name: "ROAS", url: "roas" },
      { name: "CAC", url: "cac" },
      { name: "CLV/CAC Ratio", url: "clv-cac-ratio" },
    ]);
    const ordersItems = ref([
      { name: "Total Orders", url: "total-orders" },
      { name: "AOV", url: "aov" },
      { name: "Frequency", url: "frequency" },
      { name: "Top Prodcuts", url: "top-products" },
    ]);
    const customersItems = ref([
      { name: "Top Customers", url: "top-customers" },
      { name: "CLV", url: "clv" },
    ]);
    const costsItems = ref([
      { name: "Returns", url: "returns" },
      { name: "Refunds", url: "refunds" },
      { name: "Discounts", url: "discounts" },
      { name: "Shipping", url: "shipping" },
    ]);
    return {
      revenueItems,
      marketingItems,
      ordersItems,
      customersItems,
      costsItems,
    };
  },
};
</script>
<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal {
  background: #efefef;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 80%;
  width: 100%;
  height: 100vh;
}

.modal-header,
.modal-footer {
  padding: 25px 30px;
  display: flex;
}

.modal-header {
  position: relative;
  justify-content: space-between;
  height: 6em;
  background: #efefef;
  border-bottom: 1px solid lightgray;
}

/* .modal-footer {
  border-top: 1px solid rgb(227, 231, 233);
  flex-direction: column;
  justify-content: flex-end;
} */

.modal-body {
  position: relative;
  padding: 30px 35px;
  background: #fff;
  height: 100%;
  margin: 30px;
  border-radius: 10px;
  /* overflow: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 40px;
  padding: 31px 34px 8px 0;
  cursor: pointer;
  color: #4d5051;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.list-menu-mobile {
  li {
    margin-bottom: 1em;
  }
}

.el-collapse-head-8811 {
  max-height: 34vh;
}

.ul-inner-collapse {
  padding: 1em;
  background: #f8f8f8;
  max-height: calc(45vh - 166px);
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid #efefef;
}

.ul-submenu-metrics {
  span {
    margin-bottom: 8px;
  }
  li {
    background: #fff;
    border-radius: 4px;
    .inner-link {
      background: #fff;
      padding: 8px 16px;
      border-radius: 8px;
    }
  }
  a {
    text-decoration: none;
    color: $color-active;
    font-size: 16px;
    border-radius: 8px;
  }
}

.wrapper-container {
  padding: 16px;
}
.wrapper-icon {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.wrapper-home {
    background: #eef0f1;
    color: $color-action-icon;
    &:hover,
    &:active {
      border: 2px solid $color-sky-light;
    }
  }
  &.wrapper-metrics {
    background: $color-background-purple;
    color: $color-purple;
    &:hover,
    &:active {
      border: 2px solid $color-purple-light;
    }
  }
  .icon {
    font-size: 22px;
  }
}
</style>
