export default {
  methods: {
    formatDate(date) {
      if (!date) {
        return;
      }
      const d = new Date(date);
      // TODO TO DO : pass range and format different if it's weekly, monthly, yearly...
      // const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
      const options = { month: "short", day: "numeric" };
      return d.toLocaleDateString("en-us", options);
    },
    formatKM(total, dec) {
      // console.log('mixins/numbers.js - formatKM')
      // console.log(total)
      // console.log(dec)
      if (!dec) {
        dec = 0;
      }
      total = parseFloat(total);
      if (Math.abs(total) >= 999950) {
        return (total / 1000000).toFixed(2) + "M";
      }
      if (Math.abs(total) >= 1000) {
        return (total / 1000).toFixed(1) + "K";
      }
      return total.toFixed(dec);
    },
    formatInfinity(number) {
      if (number === Infinity) {
        number = 100;
      }
      return number;
    },
    formatSeparators(number, decPlaces, thouSep, decSep) {
      var sign = number < 0 ? "-" : "";
      var i = String(
        parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
      );
      var j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + thouSep) +
        (decPlaces
          ? decSep +
            Math.abs(number - i)
              .toFixed(decPlaces)
              .slice(2)
          : "")
      );
    },
    formatCurrency(amount, money_format) {
      if (money_format) {
        let re = new RegExp(/{{.*}}/, "i");
        return money_format.replace(re, amount);
      }

      return "$" + amount;

      /*
      for (var i = 0; i < this.currencylist().length; i++) {
        if (this.currencylist()[i].value === this.quiz.attributes.currency) {
          return this.currencylist()[i].format.replace('{{amount}}', amount)
        }
      }
      return amount + ' ' + this.quiz.attributes.currency
      */
    },
    formatMoney(amount, money_format, money_with_currency_format) {
      var i = parseFloat(amount);
      var mf = "amount_no_decimals"; // default

      if (money_with_currency_format) {
        mf = money_with_currency_format.match(/\{\{(.+)\}\}/)[1];
      }

      switch (mf) {
        case "amount":
          // i = this.formatSeparators(amount, 2, ",", ".");
          i = this.formatSeparators(amount, 0, ",", ".");
          break;
        case "amount_no_decimals":
          i = this.formatSeparators(amount, 0, ",", ".");
          break;
        case "amount_with_comma_separator":
          // i = this.formatSeparators(amount, 2, ".", ",");
          i = this.formatSeparators(amount, 0, ".", ",");
          break;
        case "amount_no_decimals_with_comma_separator":
          i = this.formatSeparators(amount, 0, ".", ",");
          break;
        case "amount_with_space_separator":
          // i = this.formatSeparators(amount, 2, " ", ",");
          i = this.formatSeparators(amount, 0, " ", ",");
          break;
        case "amount_no_decimals_with_space_separator":
          i = this.formatSeparators(amount, 0, " ", ",");
          break;
        case "amount_with_apostrophe_separator":
          // i = this.formatSeparators(amount, 2, "'", ".");
          i = this.formatSeparators(amount, 0, "'", ".");
          break;
        case "amount_three_decimals":
          i = this.formatSeparators(amount, 3, ",", ".");
          break;
        default:
          // i = this.formatSeparators(amount, 2, ",", ".");
          i = this.formatSeparators(amount, 0, ",", ".");
      }

      return this.formatCurrency(i, money_format);
    },
  },
};
