<template>
  <div class="card resume-card">
    <header class="header-card">
      <p class="font s-18 c-primary f-light t-title">Conversion Rate</p>
      <div class="flex-content s-between">
        <span class="font s-20 c-primary f-bold">
          Rate:{{ " " }}
          <label class="font s-16 c-forest f-bold">15 %</label>
        </span>
        <TagValue value="7.5" />
      </div>
    </header>
    <body class="body-card">
      <apexchart
        type="line"
        :options="chartOptions"
        :series="series"
        height="200"
        width="100%"
      ></apexchart>
    </body>
  </div>
</template>

<script>
import TagValue from "@/components/UI/TagValue.vue";
export default {
  name: "Revenue-sparkline",
  components: {
    TagValue,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "line",
          toolbar: { show: false },
          animations: {
            speed: 1000,
          },
          events: {
            markerClick: function (event, chartContext, config) {
              var tooltip = chartContext.el.querySelector(
                ".apexcharts-tooltip"
              );
              var pointsArray = config.globals.pointsArray;
              var seriesIndex = config.seriesIndex;
              var dataPointIndex =
                config.dataPointIndex === -1 ? 0 : config.dataPointIndex;
              if (seriesIndex !== -1) {
                var position = pointsArray[seriesIndex][dataPointIndex];

                tooltip.style.top = position[1] + "px";
                tooltip.style.left = position[0] + "px";
              }
            },
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#49AECF",
            shadeIntensity: 0.8,
          },
        },
        plotOptions: {
          line: {
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          lineCap: "butt",
          width: 5,
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: ["2019", "2020", "2021", "2022"],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="arrow_box">' +
              "<span>" +
              w.globals.labels[dataPointIndex] +
              ": " +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "<button>" +
              "click" +
              "</button>" +
              "</div>"
            );
          },
        },
      },
      series: [
        {
          name: "Conversion Rate",
          data: [13, 11, 14, 11],
        },
      ],
    };
  },
};
</script>
<style>
/* .arrow_box {
  background: red;
  width: 150px;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
} */
</style>
