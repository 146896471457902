<template>
  <HeaderDashboard title="Dashboard" description="lorem ipsum" />
  <div class="content-max">
    <div class="inner-content-max">
      <!-- <section class="section-content">
        <p class="font s-18 f-light">
          Description of section and explain what can you do with this charts
        </p>
      </section> -->
      <section class="section-content">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <RevenueSparkline />
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <OrdersSparkline />
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <CRSparkline />
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <AOVSparkline />
          </el-col>
        </el-row>
        <HomeTabs />
      </section>
      <section class="section-content">
        <TableTopSellingProducts />
      </section>
      <section class="section-content">
        <TablePerformanceChannel />
      </section>
      <section>
        <CardOrders />
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderDashboard from "@/components/UI/HeaderDashboard.vue";
import TableTopSellingProducts from "@/components/UI/TableTopSellingProducts.vue";
import TablePerformanceChannel from "@/components/UI/TablePerformanceChannel.vue";
import RevenueSparkline from "@/components/Charts/RevenueSparkline.vue";
import OrdersSparkline from "@/components/Charts/OrdersSparkline.vue";
import CRSparkline from "@/components/Charts/CRSparkline.vue";
import AOVSparkline from "@/components/Charts/AOVSparkline.vue";
import CardOrders from "@/components/UI/CardOrders.vue";
import HomeTabs from "@/components/UI/HomeTabs.vue";

export default {
  name: "HomeView",
  components: {
    HeaderDashboard,
    TableTopSellingProducts,
    TablePerformanceChannel,
    RevenueSparkline,
    OrdersSparkline,
    CRSparkline,
    AOVSparkline,
    CardOrders,
    HomeTabs,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
};
</script>
