<template>
  <div class="wrapper">
    <el-row :gutter="10">
      <el-col
        v-for="(tab, index) in tabs"
        :key="index.id"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="6"
      >
        <div class="card-button">
          <div class="header-card-button">
            <h3 class="font s-16 f-light">{{ tab.name }}</h3>
            <button class="button c-secondary s-md" @click="currentTab = index">
              Detail
            </button>
          </div>
          <el-divider />
          <div class="body-card-button">
            <div class="content-body">
              <p class="font s-16 c-marine r-regular t-title">
                {{ tab.type }}:
              </p>
              <p>
                <b class="font s-24 f-bold c-primary">{{ tab.rate }}</b>
              </p>
              <TagValue :value="tab.dif" />
            </div>
            <div class="content-chart">
              <AOVSparkline />
            </div>
          </div>
          <!-- <button
            class="button c-secondary s-sm"
            @click="currentTab = index"
          >
            Detail
          </button> -->
        </div>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col :span="24">
        <div class="content-bd">
          <div class="tab-content">
            <div v-show="currentTab === 0">
              <RevenueSparkline />
            </div>
            <div v-show="currentTab === 1">
              <RevenueSparkline />
              <p>aaa</p>
            </div>
            <div v-show="currentTab === 2">
              <RevenueSparkline />
            </div>
            <div v-show="currentTab === 3">
              <RevenueSparkline />
            </div>
          </div>
        </div>
      </el-col>
    </el-row> -->
    <el-row :gutter="10">
      <el-col :span="18">
        <p>Table</p>
      </el-col>
      <el-col :span="6">
        <p>Table</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
//(import RevenueSparkline from "@/components/Charts/RevenueSparkline.vue";
import AOVSparkline from "@/components/Charts/AOVSparkline.vue";
import TagValue from "@/components/UI/TagValue.vue";
export default {
  components: {
    //RevenueSparkline,
    AOVSparkline,
    TagValue,
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        { name: "Revenue", type: "Net", rate: "$ 1.550.560", dif: -10 },
        { name: "Orders", type: "Total", rate: "3.670", dif: 5 },
        { name: "Conversion Rate", type: "Rate", rate: 15, dif: 4 },
        { name: "AOV", type: "Rate", rate: 24, dif: -17 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  //padding: 20px;
}
.wrapper-card-button {
  display: flex;
  //padding: 1em;
}
ul.list-card-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  li {
    width: 100%;
    margin-right: 1em;
  }
  li:last-child {
    margin-right: 0;
  }
}
.card-button {
  /* border: 1px solid $color-border; */
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1em;
  padding: 1em 1em 0 1em;
  background-color: #ffffff;
}
.header-card-button {
  display: flex;
  justify-content: space-between;
}
.body-card-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1em;
  .content-body {
    width: 50%;
  }
  .content-chart {
    width: 50%;
  }
}
.card.resume-card[data-v-400f2058] {
  padding: 0;
}
</style>
