<template>
  <div class="card resume-card">
    <header class="header-card">
      <p class="font s-18 c-primary f-light t-title">Orders</p>
      <div class="flex-content s-between">
        <span class="font s-20 c-primary f-bold">
          Total:{{ " " }}
          <label class="font s-16 c-forest f-bold">3.670</label>
        </span>
        <TagValue value="-4" />
      </div>
    </header>
    <body class="body-card">
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
        :color="color"
        height="200"
        width="100%"
      ></apexchart>
    </body>
  </div>
</template>

<script>
import TagValue from "@/components/UI/TagValue.vue";
export default {
  name: "Revenue-sparkline",
  components: {
    TagValue,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: { show: false },
          animations: {
            speed: 1000,
          },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#8661FF",
            shadeIntensity: 0.8,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 2,
          style: {
            colors: ["#fff"],
            fontSize: "10px",
          },
        },
        grid: {
          show: false,
        },
        stroke: {
          curve: "smooth",
          show: true,
          lineCap: "butt",
          width: 0,
        },
        labels: ["2019", "2020", "2021", "2022"],
        xaxis: {
          type: "category",
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
      series: [
        {
          name: "Revenue",
          data: [1530, 2070, 1650, 2150],
        },
      ],
    };
  },
};
</script>
