<template>
  <div class="card table-card">
    <div class="header-card">
      <p class="font s-24 f-light c-primary">Performance Channel</p>
      <el-popover
        placement="bottom"
        title="Top Selling Products"
        :width="230"
        trigger="hover"
        effect="dark"
      >
        <el-button>Info</el-button>
      </el-popover>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column fixed label="Default Channel" width="140">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-marine">{{ scope.row.type }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="sessions" label="Sessions" width="180">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-marine">{{ scope.row.sessions }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="cost" label="Cost" width="180">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-forest">{{ scope.row.cost }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="bounceRate" label="Bounce Rate" width="180">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-forest">{{ scope.row.bounceRate }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="ecommerceCR"
        label="Ecommerce conversion rate"
        width="220"
      >
        <template v-slot="scope">
          <p class="font s-14 f-bold c-forest">{{ scope.row.ecommerceCR }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="transactions" label="Transactions" width="180">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-forest">{{ scope.row.transactions }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="revenue" label="Revenue" width="180">
        <template v-slot="scope">
          <p class="font s-14 f-bold c-forest">{{ scope.row.revenue }}</p>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "Table-performance-channel",
  data: function () {
    return {
      tableData: [
        {
          type: "Paid Search",
          sessions: "458",
          cost: "30",
          bounceRate: "60",
          ecommerceCR: "124",
          transactions: "256",
          revenue: "3.400,75",
        },
        {
          type: "Direct",
          sessions: "567",
          cost: "30",
          bounceRate: "60",
          ecommerceCR: "124",
          transactions: "256",
          revenue: "3.400,75",
        },
      ],
    };
  },
};
</script>
