<template>
  <div class="wrapper-sidebar">
    <div class="inner-sidebar">
      <ul>
        <!-- <li
          v-for="item in sidebarItems"
          :key="item.id"
          class="side-icon"
          :style="{
            'background-color': item.background,
          }"
          :class="{
            'border:': `1px solid ${item.color}`,
            active:
              $route.name === item.name || $route.path.startsWith(item.name),
          }"
        >
          <router-link :to="item.route">
            <fas
              :style="{
                color: item.color,
              }"
              :icon="item.icon"
            />
          </router-link>
        </li> -->
        <li class="side-icon">
          <router-link to="/" class="home">
            <fas icon="circle-stop" />
          </router-link>
        </li>
        <li class="side-icon">
          <router-link to="/metrics/total-sales/" class="metrics">
            <fas icon="square-poll-vertical" />
          </router-link>
        </li>
        <li class="side-icon">
          <router-link to="/integrations" class="integrations">
            <fas icon="plug" />
          </router-link>
        </li>
        <!-- <li class="side-icon">
          <router-link to="/segments" class="segments">
            <fas icon="plug" />
          </router-link>
        </li> -->
        <li class="side-icon">
          <router-link to="/settings" class="settings">
            <fas icon="cog" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar-component",
  data: function () {
    return {
      sidebarItems: [
        {
          name: "home",
          route: "/",
          icon: "table-columns",
          background: "#F1F9FC",
          color: "#5FBCDB",
        },
        {
          name: "metrics",
          route: "/metrics/total-sales/",
          icon: "square-poll-vertical",
          background: "#F2EFFF",
          color: "#8661FF",
        },
        /*
        {
          name: "funnels",
          route: "/funnels",
          icon: "filter",
          background: "#FFF4F9",
          color: "blue",
        },
        */
        {
          name: "integrations",
          route: "/integrations",
          icon: "plug",
          background: "#FFF4F9",
          color: "#E7588A",
        },
        {
          name: "settings",
          route: "/settings",
          icon: "cog",
          background: "#F6F6F6",
          color: "525264",
        },
      ],
    };
  },
};
</script>

<style coped lang="scss">
.wrapper-sidebar {
  // TODO TO DO remove display none
  display: none !important;
  width: 60px;
  background: #fff;
  border-right: 1px solid #dfdfdf;
  height: 100vh;
  position: fixed;
  padding: 0.5em;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.inner-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 5px;
}

li.side-icon {
  display: flex;
  flex-direction: column;
  width: 44px;
  height: 44px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 12px;
  border: 1px solid #fff;
  background: $color-background-default;
  @include border-radius(6px);
  @include transition(background-color, 250ms);
  &:last-of-type {
    position: fixed;
    bottom: 10px;
    margin-bottom: 0;
  }
  a {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    font-size: 20px;
    color: $color-action-icon;
    transition: font-size 0.35s ease;
  }
  a.router-link-exact-active {
    @include border-radius(6px);
    svg {
      font-size: 24px;
      transition: font-size 0.35s ease;
    }
    &.home {
      background: $color-background-marine;
      svg {
        color: $color-marine;
      }
    }
    &.metrics {
      background: $color-background-purple;
      svg {
        color: $color-purple;
      }
    }
    &.integrations {
      background: $color-background-sky;
      svg {
        color: $color-sky;
      }
    }
    &.settings {
      background: $color-background-rose;
      svg {
        color: $color-rose;
      }
    }
  }
}
</style>
